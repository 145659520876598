import Cookies from 'universal-cookie';

const cookies = new Cookies();

const removeCookies = () => {
  const allCookies = document.cookie?.split(';');
  cookies.remove('cookiesAccepted');
  allCookies?.forEach((cookie) => {
    const splitPos = cookie?.indexOf('=');
    const name = (splitPos > -1 ? cookie?.substr(0, splitPos) : cookie).trim();
    if (name !== 'cookiesAccepted') cookies.remove(name, { path: '/', domain: document.domain });
  });
};

export default removeCookies;
