import React, { useContext } from 'react';

import lang from '../lang.json';

export const StoreContext = React.createContext({});

export const StoreProvider = ({ children, language: languageProp }) => {
  const language = ['fi', 'sv'].includes(languageProp) ? languageProp : 'en';
  const value = {
    language,
    translate: (key) => lang[language][key],
  };

  return <StoreContext.Provider value={value}>{children}</StoreContext.Provider>;
};

export const useStore = () => useContext(StoreContext);
