// extracted by mini-css-extract-plugin
export var noPadding = "v_gg g_gg";
export var mapFrameWrapper = "v_sG g_lB g_c8";
export var mapFrameWrapperOSM = "v_sH g_lC g_c8 g_bD g_bX g_bG g_bT";
export var iframeStyle = "v_f3 g_f3 g_bD g_bP g_cC g_c4 g_bY";
export var mapLayoutRight = "v_sJ g_lz g_cN";
export var mapInnerWrapper = "v_sK g_f9 g_f8 g_bW g_b5 g_cD g_cH g_cS g_c8";
export var mapText = "v_sL g_fz";
export var mapStyle = "v_sM g_bD g_bP g_cC g_c4";
export var font11pxImp = "v_bt g_bt";
export var mapInnerWrapperOSM = "v_lG g_lG g_d5 g_d0 g_cG g_cS g_cH";
export var mapInnerWrapperOSMFloaty = "v_lD g_lD g_b5";
export var mapInnerWrapperOSMFloatyMobile = "v_lF g_lF g_b5";
export var minHeight100 = "v_bT g_bT";
export var height100 = "v_bP g_bP";
export var width100 = "v_bD g_bD";
export var positionAbsolute = "v_b6";
export var positionRelative = "v_b5 g_b5";