// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "t_sm g_hp g_dw";
export var heroHeaderCenter = "t_hq g_hq g_dw g_fz";
export var heroHeaderRight = "t_hr g_hr g_dw g_fB";
export var heroParagraphLeft = "t_sn g_hl g_dz";
export var heroParagraphCenter = "t_hm g_hm g_dz g_fz";
export var heroParagraphRight = "t_hn g_hn g_dz g_fB";
export var heroBtnWrapperLeft = "t_sp g_p g_f0 g_bD g_cD g_cK";
export var heroBtnWrapperCenter = "t_sq g_f1 g_f0 g_bD g_cD g_cH";
export var heroBtnWrapperRight = "t_sr g_f2 g_f0 g_bD g_cD g_cL";
export var overlayBtnWrapper = "t_ss g_hk g_b6 g_cf g_cg g_ch g_cr g_dg";
export var design4 = "t_st g_hj g_b6 g_cf g_cg g_cr";
export var heroExceptionSmall = "t_sv z_sv";
export var heroExceptionNormal = "t_sw z_sw";
export var heroExceptionLarge = "t_sx z_sx";
export var Title1Small = "t_sy z_sy z_tc z_td";
export var Title1Normal = "t_sz z_sz z_tc z_tf";
export var Title1Large = "t_sB z_sB z_tc z_tg";
export var BodySmall = "t_sC z_sC z_tc z_tw";
export var BodyNormal = "t_sD z_sD z_tc z_tx";
export var BodyLarge = "t_sF z_sF z_tc z_ty";