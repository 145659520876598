// extracted by mini-css-extract-plugin
export var alignDiscLeft = "s_rQ g_gm g_cK g_fy";
export var alignLeft = "s_qP g_gm g_cK g_fy";
export var alignDiscCenter = "s_rR g_gn g_cH g_fz";
export var alignCenter = "s_cS g_gn g_cH g_fz";
export var alignDiscRight = "s_rS g_gp g_cL g_fB";
export var alignRight = "s_qQ g_gp g_cL g_fB";
export var footerContainer = "s_rT g_fW g_cZ";
export var footerContainerFull = "s_rV g_fT g_cZ";
export var footerHeader = "s_kZ g_kZ";
export var footerTextWrapper = "s_rW g_bD";
export var footerDisclaimerWrapper = "s_k4 g_k4 g_dn";
export var badgeWrapper = "s_rX g_bD g_cD g_cS g_cJ g_cM";
export var footerDisclaimerRight = "s_rY undefined";
export var footerDisclaimerLeft = "s_rZ undefined";
export var verticalTop = "s_r0 g_cD g_cM g_cR g_cP";
export var firstWide = "s_r1";
export var disclaimer = "s_r2 g_cD g_cM";
export var socialDisclaimer = "s_r3";
export var left = "s_r4";
export var wide = "s_r5 g_dC";
export var right = "s_r6 g_cN";
export var line = "s_gh g_gj g_dy";
export var badgeDisclaimer = "s_r7 g_cG g_cS g_cM";
export var badgeContainer = "s_r8 g_cD g_cL g_cS";
export var badge = "s_r9";
export var padding = "s_sb g_fb";
export var end = "s_sc g_cL";
export var linkWrapper = "s_sd g_fF";
export var link = "s_v g_fF";
export var colWrapper = "s_sf g_dB";
export var consent = "s_f g_f g_cG g_cS";
export var media = "s_sg g_cB g_fC";
export var itemRight = "s_sh";
export var itemLeft = "s_sj";
export var itemCenter = "s_sk";
export var exceptionWeight = "s_sl z_tF";