import React from 'react';

const formatSnippet = (snippet, pos = 0) => {
  let result = snippet;
  const start = snippet.indexOf('<script', pos);
  if (start !== -1) {
    const end = snippet.indexOf('>', start);
    const tag = snippet.substring(start, end + 1);

    let newTag;
    [' defer', ' async'].forEach((trgt) => {
      if (tag.indexOf(trgt) !== -1) {
        const idx = tag.indexOf(trgt) + 6;
        if (tag[idx] !== '=') {
          newTag = `${tag.substr(0, idx)}="true"${tag.substr(idx, tag.length)}`;
        }
      }
    });

    let newStart = end;
    if (newTag) {
      result = snippet.replace(tag, newTag);
      newStart = start + newTag.length - 1;
    }

    if (result.indexOf('<script', newStart) !== -1) result = formatSnippet(result, newStart);
  }

  return result;
};

const getSnippets = (trackers, id, accepted = false) => {
  const necessaryHead = [];
  const necessaryBefore = [];
  const necessaryStart = [];
  const head = [];
  const before = [];
  const start = [];

  if (trackers?.length > 0) {
    const DOMParser = require('dom-parser'); // eslint-disable-line global-require
    const parser = new DOMParser();

    trackers?.forEach((tracker) => {
      if (tracker.active === true) {
        if (
          tracker.visibility.visible === 'sitewide' ||
          (tracker.visibility.visible === 'all' && !tracker.visibility.pages.includes(id?.valueOf())) ||
          (tracker.visibility.visible === 'only' && tracker.visibility.pages.includes(id?.valueOf()))
        ) {
          const formated = formatSnippet(tracker.snippet);
          const doc = parser.parseFromString(`<body>${formated}</body>`, 'text/html');
          const snippet = tracker.snippet.toLowerCase();
          if (snippet.startsWith('<?xml')) {
            const xmls = doc.body.childNodes;
            if (tracker.snippetType === 'necessary' || accepted) necessaryBefore.push(...xmls);
            else before.push(...xmls);
          } else {
            const links = doc.getElementsByTagName('link');
            const styles = doc.getElementsByTagName('style');
            const metas = doc.getElementsByTagName('meta');

            Array.prototype.forEach.call(metas, (node) => {
              const attributes = {};
              if (node.attributes.length > 0) {
                Array.prototype.forEach.call(node.attributes, (attribute) => {
                  attributes[attribute.name] = attribute.value;
                });
              }

              // eslint-disable-next-line react/jsx-props-no-spreading
              if (tracker.snippetType === 'necessary' || accepted) necessaryHead.push(<meta {...attributes} />);
              else head.push(node);
            });

            Array.prototype.forEach.call(links, (node) => {
              const attributes = {};
              if (node.attributes.length > 0) {
                Array.prototype.forEach.call(node.attributes, (attribute) => {
                  attributes[attribute.name] = attribute.value;
                });
              }

              // eslint-disable-next-line react/jsx-props-no-spreading
              if (tracker.snippetType === 'necessary' || accepted) necessaryHead.push(<link {...attributes} />);
              else head.push(node);
            });

            Array.prototype.forEach.call(styles, (node) => {
              const attributes = {};
              if (node.attributes.length > 0) {
                Array.prototype.forEach.call(node.attributes, (attribute) => {
                  attributes[attribute.name] = attribute.value;
                });
              }

              if (tracker.snippetType === 'necessary' || accepted) {
                // eslint-disable-next-line react/jsx-props-no-spreading
                necessaryHead.push(<style {...attributes}>{node.innerHTML}</style>);
              } else head.push(node);
            });

            const body = doc.getElementsByTagName('body')[0];
            const nodes = body?.childNodes;
            Array.prototype.forEach.call(nodes, (node, index) => {
              if (
                tracker.placement === 'start' &&
                ['p', 'div', 'script', 'noscript'].includes(node?.nodeName?.toLowerCase())
              ) {
                if (tracker.snippetType === 'necessary' || accepted) necessaryStart.splice(0, 0, node);
                else start.splice(0, 0, node);
              } else if (
                tracker.placement === 'before' &&
                ['p', 'div', 'script', 'noscript'].includes(node?.nodeName?.toLowerCase())
              ) {
                if (tracker.snippetType === 'necessary' || accepted) necessaryBefore.push(node);
                else before.push(node);
              } else if (tracker.placement === 'head' && node?.nodeName?.toLowerCase() === 'script') {
                const attributes = {};
                if (node.attributes.length > 0 && (tracker.snippetType === 'necessary' || accepted)) {
                  Array.prototype.forEach.call(node.attributes, (attribute) => {
                    attributes[attribute.name] = attribute.value;
                  });
                }

                const newElement = (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <script key={`script_${index}`} {...attributes}>
                    {node.innerHTML ? `${node.innerHTML}` : null}
                  </script>
                );
                if (tracker.snippetType === 'necessary' || accepted) necessaryHead.push(newElement);
                else head.push(node);
              } else if (tracker.placement === 'head' && node?.nodeName?.toLowerCase() === 'noscript') {
                if (tracker.snippetType === 'necessary' || accepted) {
                  necessaryHead.push(<noscript>{`${node.innerHTML}`}</noscript>);
                } else head.push(<noscript>{`${node.innerHTML}`}</noscript>);
              }
            });
          }
        }
      }
    });
  }

  return { necessaryHead, necessaryStart, necessaryBefore, head, before, start };
};

export default getSnippets;
