import React from 'react';
import clsx from 'clsx';

import ButtonEditor from '../../LayoutComponents/ButtonEditor';
import * as styles from '../styles.module.css';

const ButtonElement = ({
  item,
  subIndex,
  space,
  themeButtonStyle,
  themeData,
  buttons,
  pagePathList,
  articlePathList,
  filePathList,
  categoryPathList,
  sectionSlugs,
}) => {
  return (
    <div
      style={{
        ...themeButtonStyle,
        marginBottom: space ? `${space}px` : undefined,
        marginTop: space ? `${space}px` : undefined,
      }}
      className={clsx(subIndex == null && styles.element)}
    >
      <ButtonEditor
        themeData={themeData}
        data={item}
        buttons={buttons}
        pagePathList={pagePathList}
        articlePathList={articlePathList}
        filePathList={filePathList}
        categoryPathList={categoryPathList}
        sectionSlugs={sectionSlugs}
      />
    </div>
  );
};

export default ButtonElement;
