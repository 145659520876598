import React from 'react';
import HTMLParser from 'html-react-parser';
import clsx from 'clsx';

import * as styles from '../styles.module.css';

const TextElement = ({ item, componentIndex, subIndex, space, type, parent, themeData }) => {
  const cols = type === 'quote' || subIndex != null ? '' : 'col-10 col-lg-8';

  let content;
  let style;
  const classes = [cols];

  if (item?.type === 'HEADING' || type === 'subtitle') {
    content = `<h2>${item.text}</h2>`;
    if (type === 'subtitle') classes.push(styles.articleText);
    if (!parent) {
      style = {
        fontFamily: themeData.typography.heading.name,
        fontWeight: themeData.typography.heading.weight,
        lineHeight: themeData.typography.heading.lineHeight,
        letterSpacing: themeData.typography.heading.letterSpacing,
        textTransform: themeData.typography.heading.textTransform,
      };
    }
  } else if (item?.type?.startsWith('PARAGRAPH') || ['paragraph', 'quote'].includes(type)) {
    content = parent !== 'article' ? `<span>${item.text}</span>` : item.text;
    if (type !== 'quote' && componentIndex == null) classes.push(styles.element);
    if (parent === 'article' && type === 'paragraph') classes.push(styles.articleText);
    if (!parent) {
      style = {
        fontFamily: themeData.typography.default.name,
        fontWeight: themeData.typography.default.weight,
        lineHeight: themeData.typography.default.lineHeight,
        letterSpacing: themeData.typography.default.letterSpacing,
        textTransform: themeData.typography.default.textTransform,
      };
    }
  }

  if (type !== 'quote' && space != null) style = { ...style, marginBottom: `${space}px`, marginTop: `${space}px` };
  style = { ...style, lineHeight: 1.5 };

  let elem = null;
  if (content !== undefined) {
    elem = (
      <div className={clsx(classes)} style={style}>
        {HTMLParser(content)}
      </div>
    );
  }

  return elem;
};

export default TextElement;
