import findCategoryById from './findCategoryById';

const formStyle = (
  headerTarget,
  elementsTarget,
  styleTarget,
  linkTarget,
  buttons,
  categoryAuthor,
  useDefault,
  defaultAuthor,
) => {
  const finalStyle = {};

  ['cNameActive', 'cDescActive', 'headerImage', 'headerColor', 'headerBg'].forEach((trgt) => {
    finalStyle[trgt] = headerTarget?.[trgt];
  });
  [
    'showDate',
    'showExcerpt',
    'showThumbnails',
    'sortOrder',
    'articleCount',
    'sidebar',
    'showSidebar',
    'owner',
    'searchText',
  ].forEach((trgt) => {
    finalStyle[trgt] = elementsTarget?.[trgt];
  });
  ['layout', 'fontColor', 'bg'].forEach((trgt) => {
    finalStyle[trgt] = styleTarget?.[trgt];
  });
  ['linkColor', 'linkType', 'linkText'].forEach((trgt) => {
    finalStyle[trgt] = linkTarget?.[trgt];
  });
  const buttonId =
    ((buttons || []).find((button) => button?.id === linkTarget.linkBtnId) && linkTarget.linkBtnId) || '';
  finalStyle.linkBtnId = buttonId;
  finalStyle.bg = styleTarget.styles?.bg ?? styleTarget.bg;

  if (useDefault && (defaultAuthor || categoryAuthor)) finalStyle.owner = categoryAuthor ?? defaultAuthor;

  return finalStyle;
};

const getCategoryStyle = (categories, id, color, buttons, section, defaultAuthor) => {
  let finalStyle = formStyle(section, section, section, section, buttons);
  const category = findCategoryById(categories, id);
  if (category) {
    if (category.globalHeader != null || !category.parent) {
      const headerTarget = category.globalHeader === false ? category : section;
      const elementsTarget = category.globalElements === false ? category : section;
      const styleTarget = category.globalStyle === false ? category : section;
      const linkTarget = category.globalLink === false ? category : section;

      finalStyle = formStyle(
        headerTarget,
        elementsTarget,
        styleTarget,
        linkTarget,
        buttons,
        category.author,
        category.globalElements,
        defaultAuthor,
      );
    } else if (category.parent) {
      finalStyle = getCategoryStyle(categories, category.parent, color, buttons, section, defaultAuthor);
    }
  }

  return finalStyle;
};

export default getCategoryStyle;
