/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import clsx from 'clsx';

import SectionMedia from '../SectionMedia';

import * as styles from './styles.module.css';

const CustomSectionMedia = ({ variant = 'default', data, style, className, settings, align, ...rest }) => {
  const resolvedSettings = {
    crop: true,
    round: true,
    width: [0, 100, '%'],
    align: true,
    ...settings,
  };
  const resolvedSettingsData = {
    crop: false,
    round: false,
    width: resolvedSettings.width ? resolvedSettings.width?.[1] : null,
    align: null,
    ...data,
  };

  const classes = [styles.root];
  const wrapperStyle = {};

  if (variant === 'fixedHeight') {
    wrapperStyle.height = `${resolvedSettings.height}px`;
  }

  if (!data.icon) {
    if (variant === 'default' && resolvedSettingsData.width !== null)
      wrapperStyle.width = `${resolvedSettingsData.width}${resolvedSettings.width?.[2]}`;
    if (
      ['Right', 'Center'].includes(resolvedSettingsData.align) ||
      (!resolvedSettingsData.align && ['Right', 'Center'].includes(align))
    ) {
      wrapperStyle.marginLeft = 'auto';
    }
    if (
      ['Left', 'Center'].includes(resolvedSettingsData.align) ||
      (!resolvedSettingsData.align && ['Left', 'Center'].includes(align))
    ) {
      wrapperStyle.marginRight = 'auto';
    }

    if (resolvedSettingsData.crop || resolvedSettingsData.round) {
      classes.push(styles.crop);
      if (variant === 'fixedHeight') wrapperStyle.width = `${resolvedSettings.height}px`;
      if (resolvedSettingsData.round) classes.push(styles.round);
    }
  }

  return (
    <div style={{ ...style, ...wrapperStyle }} className={clsx(classes, styles[variant], className)}>
      <SectionMedia
        {...rest}
        mediaType={data.icon ? 'ICON' : 'IMAGE'}
        className={styles.wrapper}
        imageClassName={styles.image}
        src={data.CDNLink ? data.CDNLink : `${process.env.IMAGES_CDN}/${data.src}`}
        alt={data.alt}
        data={resolvedSettingsData}
        align={align}
      />
    </div>
  );
};

export default CustomSectionMedia;
