import React from 'react';
import clsx from 'clsx';

import * as mapStyles from '../../../../../map.module.css';
import OSMap from '../../LayoutComponents/OSMap';
import * as styles from '../styles.module.css';

const MapElement = ({ item, section, index, componentIndex, subIndex, space, name }) => {
  const align = styles[`align${section?.styles?.align || 'Left'}`];
  const { height = 240, zoomLevel = 15, style = 'tinted', popupText, lat, lng } = item || {};
  const themeStyle = `${style}${name?.toLowerCase()}`;

  return (
    <div
      className={clsx(styles.map, align, mapStyles[themeStyle], subIndex == null && styles.element)}
      style={{
        marginBottom: space ? `${space}px` : undefined,
        marginTop: space ? `${space}px` : undefined,
        height: `${height}px`,
      }}
    >
      <OSMap
        lat={lat}
        lng={lng}
        zoom={zoomLevel}
        popupText={popupText}
        id={section._id}
        index={index}
        componentIndex={componentIndex}
        subIndex={subIndex}
      />
    </div>
  );
};

export default MapElement;
