import getColor from './getColor';

const invertHexColor = (hex, colors) => {
  let color = hex;
  if (colors) color = getColor(hex, colors);
  // Expand shorthand form
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const expanded = color.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(expanded);
  const r = (255 - parseInt(result[1], 16)).toString(16);
  const g = (255 - parseInt(result[2], 16)).toString(16);
  const b = (255 - parseInt(result[3], 16)).toString(16);

  return `#${r.padStart(2, '0')}${g.padStart(2, '0')}${b.padStart(2, '0')}`;
};

export default invertHexColor;
