import React from 'react';

import * as styles from './styles.module.css';

class SimpleCheckbox extends React.PureComponent {
  render() {
    const flex = this.props.flex === 'column' ? styles.checkboxInputColumn : styles.checkboxInputRow;

    return (
      <div className={`form_checkbox_${this.props.index}_${this.props.idx}_${this.props.sectionId} ${flex}`}>
        <input
          id={`checkboxOptionGroup_${this.props.index}_${this.props.idx}_${this.props.sectionId}`}
          type="checkbox"
          name={`checkboxOptionGroup_${this.props.index}_${this.props.sectionId}`}
          className={this.props.flex === 'column' ? styles.inputOptionColumn : styles.inputOptionRow}
          value={this.props.value}
          data-index={this.props.idx}
        />
        <label htmlFor={`checkboxOptionGroup_${this.props.index}_${this.props.idx}_${this.props.sectionId}`}>
          {this.props.value}
        </label>
      </div>
    );
  }
}

export default SimpleCheckbox;
