import React from 'react';

import * as styles from './styles.module.css';

class IEWarning extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true,
      en: "Our site doesn't work optimally with IE11 and older versions. By switching to a newer version you'll get a better user exprience.",
      fi: 'Sivustomme ei toimi optimaalisesti IE11 ja vanhemmilla versioilla. Vaihtamalla uudempaan selainversioon saat paremman käyttökokemuksen.',
      lang: 'en',
    };
  }

  componentDidMount() {
    if (
      typeof window !== 'undefined' &&
      typeof document !== 'undefined' &&
      window.navigator.userAgent.match(/(MSIE|Trident)/)
    ) {
      let userLanguage = 'en';
      if (navigator.languages && navigator.languages.length) {
        [userLanguage] = navigator.languages;
      } else {
        userLanguage = navigator.language || navigator.browserLanguage;
      }

      userLanguage = userLanguage.toLowerCase();
      if (userLanguage === 'fi' || userLanguage === 'fi-fi') userLanguage = 'fi';
      else userLanguage = 'en';
      this.setState({
        lang: userLanguage,
      });
    }
  }

  render() {
    if (this.state.visible && typeof window !== 'undefined' && window.navigator.userAgent.match(/(MSIE|Trident)/)) {
      return (
        <div className={styles.warningWrapper}>
          <div style={{ float: 'left' }}>
            <p>{this.state[this.state.lang]}</p>
          </div>
          <button className={styles.btn} onClick={() => this.setState({ visible: false })}>
            <i className="entypo icon-cancel" style={{ color: '#fff' }} aria-hidden="true" />
          </button>
        </div>
      );
    }

    return null;
  }
}

export default IEWarning;
