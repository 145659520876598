import React from 'react';
import clsx from 'clsx';

import * as styles from '../styles.module.css';

const QuoteElement = ({ item, index, componentIndex, subIndex, space, createText, colors = [] }) => {
  return (
    <div
      className={clsx(subIndex == null && 'col-8', subIndex == null ? styles.quoteWrapper : styles.quote)}
      style={space ? { marginBottom: `${space}px`, marginTop: `${space}px` } : undefined}
    >
      <div className={styles.quoteBar} style={{ backgroundColor: colors[0] }} />
      <div className={styles.quoteText}>
        <div style={{ height: '10px' }} />
        {createText(item, index, 'quote', componentIndex, subIndex)}
        <div style={{ height: '10px' }} />
      </div>
    </div>
  );
};

export default QuoteElement;
