const getImgExtension = (image) => {
  let ext;
  if (!image.extensions || image.extensions.length === 0) {
    const filename = image.s3Filename || image.filename;
    const n = filename?.lastIndexOf('.');
    if (n > -1) {
      ext = filename.substr(n);
      ext = !['.svg', '.gif'].includes(ext) ? ext : null;
    } else ext = '';
  }

  return ext;
};

export default getImgExtension;
