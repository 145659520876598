import React from 'react';
import HTMLParser from 'html-react-parser';

import OSMap from '../../LayoutComponents/OSMap';
import * as mapStyles from '../../../../../map.module.css';

import GMAP from './GMAP';
import * as styles from './styles.module.css';

class MapLayout extends React.PureComponent {
  constructor(props) {
    super(props);

    const dataIndex = props.section.data.findIndex((elem) => elem.type === 'DATA');

    const layout = props.section.styles.layout ? props.section.styles.layout : 'left';
    const zoomLevel =
      dataIndex > -1 && props.section.data[dataIndex].zoomLevel ? props.section.data[dataIndex].zoomLevel : 14;
    const mapStyle =
      dataIndex > -1 && props.section.data[dataIndex].style ? props.section.data[dataIndex].style : undefined;
    const lat = dataIndex > -1 && props.section.data[dataIndex].lat ? props.section.data[dataIndex].lat : 0;
    const lng = dataIndex > -1 && props.section.data[dataIndex].lng ? props.section.data[dataIndex].lng : 0;
    const popupText =
      dataIndex > -1 && props.section.data[dataIndex].popupText ? props.section.data[dataIndex].popupText : '';

    this.state = {
      layout,
      zoomLevel,
      mapStyle,
      lat,
      lng,
      popupText,
      mapProvider: props.mapProvider || 'OSM',
    };
  }

  render() {
    let { mapStyle } = this.state;
    if (this.state.mapStyle === 'light' || this.state.mapStyle === 'undefined' || !this.state.mapStyle)
      mapStyle = 'tinted';

    const themeStyle = mapStyles[`${mapStyle}${this.props.themeData.name.toLowerCase()}`];
    const cols =
      this.state.layout !== 'center' && this.state.layout !== 'full' && this.state.layout !== 'fullFloaty'
        ? 'col-12 col-md-6'
        : 'col-12';

    let mapElement;
    if (this.state.mapProvider === 'OSM') {
      mapElement = (
        <OSMap
          zoom={this.state.zoomLevel}
          popupText={this.state.popupText}
          layout={this.state.layout}
          lat={this.state.lat}
          lng={this.state.lng}
          id={this.props.section._id}
        />
      );
    } else {
      mapElement = <GMAP lat={this.state.lat} lng={this.state.lng} zoom={this.state.zoomLevel} />;
    }

    let innerWrapper = styles.mapInnerWrapper;
    let { mapFrameWrapper } = styles;

    if (this.state.mapProvider === 'OSM') {
      if (this.state.layout === 'fullFloaty') {
        if (this.props.mobileView) {
          innerWrapper = styles.mapInnerWrapperOSMFloatyMobile;
          mapFrameWrapper = `${styles.mapFrameWrapperOSM} ${styles.positionRelative}`;
        } else {
          innerWrapper = styles.mapInnerWrapperOSMFloaty;
          mapFrameWrapper = `${styles.mapFrameWrapperOSM} ${styles.positionAbsolute} ${styles.height100}`;
        }
      } else {
        innerWrapper = styles.mapInnerWrapperOSM;
        mapFrameWrapper = `${styles.mapFrameWrapperOSM} ${styles.minHeight100}`;
      }
    }

    let layoutAlign = '';
    if (this.state.layout === 'right') layoutAlign = `${styles.mapLayoutRight}`;

    const content = [];
    this.props.section.data.forEach((elem, i) => {
      let result;
      if (elem.active && (elem.type === 'HEADINGS/HEADING-TWO' || elem.type === 'PARAGRAPH/PARAGRAPH')) {
        let data;
        if (elem.type === 'HEADINGS/HEADING-TWO') data = `<h2>${elem.text}</h2>`;
        else data = `<span>${elem.text}</span>`;

        result = (
          <div key={`${this.props.section._id}_elem_${i}`} className={styles.mapText}>
            {HTMLParser(data)}
          </div>
        );

        if (result) content.push(result);
      }
    });

    return (
      <div
        className={`
        ${this.state.layout === 'full' || this.state.layout === 'fullFloaty' ? '' : 'container'}
        ${this.state.layout === 'full' || this.state.layout === 'fullFloaty' ? styles.noPadding : ''}`}
      >
        <div className={`row no-gutters ${layoutAlign}`}>
          {content.length > 0 && (
            <div className={`${cols} ${innerWrapper}`} style={this.props.boxStyle}>
              <div>{content}</div>
            </div>
          )}
          <div className={`${cols} ${mapFrameWrapper} ${themeStyle}`}>
            <div className={`${styles.mapStyle} ${styles.width100} ${styles.positionAbsolute} ${styles.height100}`}>
              {mapElement}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MapLayout;
