import React from 'react';
import clsx from 'clsx';

import { formColor } from '../../../../../helper';
import * as styles from '../styles.module.css';

const SeparatorElement = ({ item, section, subIndex, space, colors = [] }) => {
  const { width = 20, height = 2, separatorType = 'solid', color = 'COLOR_0', align = null } = item || {};
  const sAlign = styles[`align${align || section?.styles?.align || 'Left'}`];
  let bColor = 'transparent';
  let separator = separatorType;
  if (separatorType !== 'space') {
    bColor = formColor({ solid: color }, false, 1, undefined, colors)?.backgroundColor;
  } else separator = 'solid';
  const border = `${separator} ${height}px ${bColor}`;

  return (
    <div
      className={clsx(styles.separatorWrapper, sAlign, subIndex == null && styles.element)}
      style={{
        marginBottom: space ? `${space}px` : undefined,
        marginTop: space ? `${space}px` : undefined,
      }}
    >
      <div style={{ width: `${width}%` }}>
        <hr style={{ width: '100%', border: 'none', borderBottom: border }} />
      </div>
    </div>
  );
};

export default SeparatorElement;
