import React from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';

import { constructLink, formColor } from '../../../../../helper';
import getLinkTarget from '../../../../../getLinkTarget';
import Image from '../../../../Image';

import * as styles from './styles.module.css';

class SectionMedia extends React.Component {
  constructor(props) {
    super(props);

    const { link, type } = constructLink(
      props.data.linkObj,
      props.pagePathList,
      props.articlePathList,
      props.filePathList,
      props.categoryPathList,
      props.sectionSlugs,
    );

    this.state = {
      type,
      link,
    };
  }

  render() {
    const align = styles.alignCenter;
    const id = this.props && this.props.data ? this.props.data.id : undefined;

    const isLink = !!this.state.link;
    const isLocal = isLink && !['EXTERNAL', 'FILE'].includes(this.state.type);
    let previewTarget = '_blank';
    if (this.state.type !== 'FILE' && isLink) previewTarget = getLinkTarget(this.props.data, 'openLinkInNewTab');

    let color;
    if (this.props.mediaType === 'ICON' && this.props.data.icon.color) {
      ({ color } = this.props.data.icon);
      if (this.props.data.icon.color.startsWith('COLOR_') && this.props.colors && this.props.colors.length === 4) {
        color = formColor({ solid: color }, false, 1, undefined, this.props.colors).backgroundColor;
      }
    }

    const mediaElement =
      this.props.mediaType === 'IMAGE' ? (
        <Image
          id={id}
          url={this.props.src}
          sizes={this.props.sizes || '100vw'}
          imageStyleName={this.props.imageStyleName}
          alt={this.props.alt}
          onClick={this.props.onImageClick}
          transform={this.props.data ? this.props.data.transform : undefined}
          className={this.props.imageClassName}
          images={this.props.images}
          loadingStrategy={this.props.loadingStrategy}
        />
      ) : (
        <div
          className={clsx(
            this.props.align === undefined ? align : styles[`align${this.props.align}`],
            styles.iconWrapper,
            styles[this.props.iconStyleName],
          )}
        >
          <span
            className={`entypo ${this.props.data.icon.class}`}
            style={{
              fontSize: this.props.data.icon.size,
              color,
            }}
          />
        </div>
      );

    return (
      <div
        className={clsx(
          styles.overflowHidden,
          this.props.align ? styles[`align${this.props.align}`] : align,
          styles[this.props.wrapperStyleName],
          this.props.className,
        )}
      >
        {isLink && isLocal && previewTarget !== '_blank' ? (
          <Link target={previewTarget} rel="noopener noreferrer" to={this.state.link} draggable="false">
            {mediaElement}
          </Link>
        ) : isLink && (!isLocal || previewTarget === '_blank') ? (
          <a target={previewTarget} rel="noopener noreferrer" href={this.state.link} draggable="false">
            {mediaElement}
          </a>
        ) : (
          mediaElement
        )}
      </div>
    );
  }
}

export default SectionMedia;
