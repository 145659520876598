import React from 'react';

import '../../../../../pages/leaflet.css';

import * as styles from './styles.module.css';

let L;

if (typeof window !== 'undefined') {
  L = require('leaflet/dist/leaflet'); // eslint-disable-line global-require
}

class OSMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lat: this.props.lat ?? 0,
      lng: this.props.lng ?? 0,
      zoom: this.props.zoom ?? 15,
      popupText: this.props.popupText,
    };
  }

  componentDidMount() {
    const tileAddress = 'https://maps.oidom.com/mapproxy/tiles/1.0.0/osm/EPSG900913/{z}/{x}/{y}.png';

    this.map = L.map(`map_${this.props.id}_${this.props.index}_${this.props.componentIndex}_${this.props.subIndex}`, {
      center: [this.state.lat, this.state.lng],
      zoom: this.state.zoom,
      scrollWheelZoom: false,
      layers: [
        L.tileLayer(tileAddress, {
          attribution: '&copy; <a href="http://osm.org/copyright" >OpenStreetMap</a> contributors',
          tms: true,
        }),
      ],
    });

    const icon = new L.Icon.Default({
      imagePath: `${process.env.IMAGES_CDN}/leaflet/`,
      iconUrl: 'marker-icon.png',
      shadowUrl: 'marker-shadow.png',
      iconRetinaUrl: 'marker-icon-2x.png',
    });

    this.marker = L.marker([this.state.lat, this.state.lng], {
      icon,
    }).addTo(this.map);

    if (this.marker && this.state.popupText && this.state.popupText.length > 0) {
      this.marker.bindPopup(this.state.popupText);
    }

    setTimeout(() => {
      this.marker.openPopup();
    }, 1200);
  }

  render() {
    return (
      <div
        id={`map_${this.props.id}_${this.props.index}_${this.props.componentIndex}_${this.props.subIndex}`}
        className={styles.mapStyle}
      />
    );
  }
}

export default OSMap;
