import React from 'react';
import clsx from 'clsx';

import * as styles from '../styles.module.css';

const RowElement = ({ item, section, index, getElement }) => {
  const getAlign = (value) => {
    let align = 'flex-start';
    let textAlign = 'left';
    let rowAlign = 'Left';
    switch (value) {
      case 'center':
        align = 'center';
        textAlign = 'center';
        rowAlign = 'Center';
        break;
      case 'br':
        align = 'flex-end';
        textAlign = 'right';
        rowAlign = 'Right';
        break;
      default:
        break;
    }

    return { align, textAlign, rowAlign };
  };

  const style = {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    marginTop: '16px',
    marginBottom: '16px',
  };

  const vertical = getAlign(item.verticalAlign);
  style.paddingLeft = `${item.gutter ?? 60}px`;
  style.paddingRight = `${item.gutter ?? 60}px`;
  style.justifyContent = getAlign(item.horizontalAlign)?.align ?? 'flex-start';
  style.alignItems = vertical?.align ?? 'flex-start';
  style.textAlign = vertical?.textAlign ?? 'left';
  const cols = item.data.map((col, subIdx) => {
    const elems = [];
    if (col?.data?.length > 0) {
      col.data.forEach((i, subSubIdx) => {
        if (i.type === 'GALLERY') elems.push(getElement(i, index, subIdx, subSubIdx, item.space ?? 20));
        else elems.push(getElement(i, subSubIdx, index, subIdx, item.space ?? 20, vertical.rowAlign));
      });
    }

    return (
      <div
        key={`${section._id}_Row_${index}_Col_${subIdx}`}
        className={clsx('col-12', col.columnCount !== 12 && `col-lg-${col.columnCount}`)}
        style={style}
      >
        {elems}
      </div>
    );
  });

  return <div className={`row ${styles.customRow}`}>{cols}</div>;
};

export default RowElement;
