// extracted by mini-css-extract-plugin
export var articleWrapper = "n_p5";
export var articleText = "n_p6 g_fy g_dw";
export var header = "n_p7 g_b5 g_bD";
export var headerImageWrapper = "n_p8 g_cl g_b6 g_cg g_cf g_cb g_ch g_cn g_cV";
export var headerGradient = "n_p9 g_cl g_b6 g_cg g_cf g_cb g_ch";
export var headerGradientOverlay = "n_qb g_cl g_b6 g_cg g_cf g_cb g_ch";
export var headerContentWrapper = "n_qc g_cD g_cS g_cH g_cM g_cp g_b5";
export var headerContentWrapperAlt = "n_qd g_cD g_cS g_cH g_cM g_cp g_b5";
export var contentWrapper = "n_qf g_bD";
export var dateTag = "n_qg g_dj g_dv g_ft g_bD";
export var icon1 = "n_qh g_dS";
export var icon2 = "n_qj g_dS g_dM";
export var tagBtn = "n_qk g_c4 g_dH g_fs g_fF";
export var headerText = "n_ql g_bD g_dj g_dw g_cZ";
export var center = "n_qm g_fz";
export var videoIframeStyle = "n_qn g_f3 g_bD g_bP g_cC g_c4 g_bY";
export var articleImageWrapper = "n_qp g_dw g_b5";
export var articleImageWrapperIcon = "n_qq g_dw g_b5";
export var articleRow = "n_qr g_cH";
export var quoteWrapper = "n_hH g_cG g_cS g_dw";
export var quoteBar = "n_qs g_bP";
export var quoteText = "n_qt";
export var authorBox = "n_qv g_bD";
export var authorRow = "n_qw g_cH g_cZ g_dC";
export var separator = "n_qx g_bD";
export var line = "n_gh g_bD g_gh g_d1 g_dC";
export var authorImage = "n_l4 g_db g_b5 g_bD g_dx";
export var authorText = "n_qy g_dl g_dx";
export var masonryImageWrapper = "n_qz";
export var bottomSeparator = "n_qB g_bD g_dC";
export var linksWrapper = "n_qC g_dl g_fy";
export var comments = "n_qD g_cH g_dC";
export var sharing = "n_qF g_cD g_cH g_cM";
export var shareText = "n_qG g_bD g_fz";
export var icon = "n_qH";
export var customRow = "n_qJ g_cH g_b5";
export var text = "n_qK";
export var SubtitleSmall = "n_qL";
export var SubtitleNormal = "n_qM";
export var SubtitleLarge = "n_qN";