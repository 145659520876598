// extracted by mini-css-extract-plugin
export var datasheetRowWrapperCenter = "q_q4 g_cH";
export var datasheetRowWrapper = "q_l8 g_l8 g_cN";
export var datasheetLeftWrapper = "q_q5 g_cD g_cS";
export var datasheetWrapperFull = "q_q6 g_dH";
export var datasheetWrapperFullLeft = "q_nF g_dH g_cD g_cS g_cH g_cX";
export var contentWrapper = "q_qf g_l9";
export var contentWrapperCenter = "q_q7 g_mf";
export var datasheetLeftWrapperCenter = "q_q8 g_mc g_cD g_cH g_cS g_cM";
export var datasheetImageCenterWrapper = "q_bc g_bc g_bD g_dy";
export var datasheetRightWrapperCenter = "q_q9 g_mb";
export var datasheetFooter = "q_md g_md";
export var alignLeft = "q_qP g_fy";
export var alignCenter = "q_cS g_fz";
export var alignRight = "q_qQ g_fB";
export var datasheetInnerWrapperNewLeft = "q_rb g_gc g_bW g_b5 g_cD g_cS g_fm";
export var datasheetInnerWrapperLeft = "q_rc g_gb g_bW g_b5 g_cD g_cS g_c8 g_fm g_fd";
export var datasheetInnerWrapperRight = "q_rd g_gc g_bW g_b5 g_cD g_cS g_fm";
export var datasheetRowWrapperReverse = "q_rf g_cN";
export var datasheetSubtitle = "q_rg g_dB";
export var tableRow = "q_rh";
export var cell = "q_rj";
export var tableRowWrapper = "q_rk g_bD g_fy";
export var tableHeadingCell = "q_rl";
export var tableHeading = "q_rm";
export var tableRowStriped = "q_rn";
export var tableRowHeading = "q_rp";
export var dataTable = "q_rq g_bD";
export var imageWrapper = "q_rr g_gd g_b5";
export var imageWrapperFull = "q_rs g_bP g_bD g_C g_b5";
export var imageWrapperIcon = "q_rt";
export var titleMargin = "q_rv g_dw";
export var datasheetInnerInnerWrapper = "q_rw g_bD";
export var hasLabels = "q_rx";
export var label = "q_ry";
export var SmallSmall = "q_rz z_rz z_tc z_tz";
export var SmallNormal = "q_rB z_rB z_tc z_tB";
export var SmallLarge = "q_rC z_rC z_tc z_tx";