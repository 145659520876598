import React from 'react';
import { Link } from 'gatsby';

import { formColor } from '../../../../helper';
import Image from '../../../Image';
import imgHelper from '../../../Image/helper';
import getNavHeight from '../../../../helpers/getNavHeight';
import getLLHeight from '../../../../helpers/getLLHeight';
import { InfoRowHeightContext } from '../../../NavigationBar/InfoRowHeightContext';
import findCategoryById from '../../../../helpers/findCategoryById';
import getCategoryStyle from '../../../../helpers/getCategoryStyle';

import * as styles from './styles.module.css';

class LayoutContainer extends React.Component {
  constructor(props) {
    super(props);

    const { section } = props;
    const layout = section !== undefined ? props.section.styles.layout : undefined;
    const recStyles = section !== undefined ? props.section.styles : undefined;

    const { logo, themeData } = props.pageContext;
    const { logoHeight, linkHeight } = getLLHeight(logo, themeData);

    /* jshint ignore:start */
    const componentName = props.pageContext.components[props.section.type];
    // eslint-disable-next-line
    const specificLayout = require(`src/components/EditorPanel/ManageSections/Layouts/${componentName}`);
    /* jshint ignore:end */

    let categoryStyle;
    if (props.pageContext.category && section.type === 'ARTICLEMOTHER') {
      const category = findCategoryById(section.styles?.categories ?? [], props.pageContext.category._id);
      if (category) {
        categoryStyle = getCategoryStyle(
          section.styles?.categories || [],
          category?._id,
          themeData?.colors || [],
          props.pageContext.buttons,
          section.styles,
        );
      }
    }

    this.state = {
      section,
      layout,
      recStyles,
      SpecificLayout: specificLayout.default,
      logoHeight,
      linkHeight,
      categoryStyle,
    };
  }

  render() {
    if (
      this.props.pageContext.category &&
      !['NAVIGATION', 'ARTICLEMOTHER', 'FOOTER'].includes(this.props.section.type)
    ) {
      return null;
    }

    const navHeight = getNavHeight(
      this.context,
      this.state.logoHeight,
      this.state.linkHeight,
      this.props.pageContext.navigation.styles.layout,
      this.props.isScrolling && this.props.pageContext.navigation.styles.fixed_top,
    );

    let full = '';
    if ((this.state.section.styles.full && this.props.matches) || !this.props.matches) {
      full = 'Full';
    }

    const { articleMother } = this.props.pageContext;
    const template = this.state.section.type.toUpperCase();

    if (this.state.section === undefined) return null;

    let boxStyle;
    if (
      this.state.recStyles?.box &&
      this.state.recStyles?.box.styles &&
      this.state.recStyles?.box.styles.backgroundColor &&
      this.state.recStyles?.box.styles.backgroundColor.active
    ) {
      let { backgroundColor } = this.state.recStyles?.box.styles;
      if (
        this.state.recStyles?.box.styles.backgroundColor.solid === '' &&
        (!this.state.recStyles?.box.styles.backgroundColor.gradient ||
          this.state.recStyles?.box.styles.backgroundColor.gradient.from === '')
      ) {
        const [solid] = this.props.pageContext.themeData.colors;
        backgroundColor = { solid };
      }

      boxStyle = formColor(
        backgroundColor,
        false,
        this.state.recStyles?.box.styles.backgroundColor.opacity,
        undefined,
        this.props.pageContext.themeData.colors,
      );
    }

    let overlay;
    if (this.state.recStyles?.styles.backgroundColor.active) {
      let { backgroundColor } = this.state.recStyles?.styles;
      if (
        this.state.recStyles?.styles.backgroundColor.solid === '' &&
        (!this.state.recStyles?.styles.backgroundColor.gradient ||
          this.state.recStyles?.styles.backgroundColor.gradient.from === '')
      ) {
        backgroundColor = { solid: '#FFFFFF' };
      }

      overlay = formColor(
        backgroundColor,
        false,
        this.state.recStyles?.styles.backgroundColor.opacity,
        undefined,
        this.props.pageContext.themeData.colors,
      );
    }

    let backgroundColor;
    let bg;
    if (this.state.categoryStyle || (this.state.recStyles?.styles?.bg && this.state.recStyles?.styles.bg.active)) {
      backgroundColor = this.state.categoryStyle?.bg ?? this.state.recStyles?.styles.bg;
      if (!backgroundColor?.solid && !backgroundColor?.gradient?.from) {
        backgroundColor = { solid: '#FFFFFF' };
      }

      bg = formColor(
        backgroundColor,
        false,
        this.state.categoryStyle?.bg?.opacity ?? this.state.recStyles?.styles?.bg?.opacity ?? 1,
        undefined,
        this.props.pageContext.themeData.colors,
      );
    }

    let size = 'cover';

    if (this.state.recStyles && this.state.recStyles?.image) {
      size = this.state.recStyles?.image.size || 'cover';
    }

    const type = this.state.section.type.toLowerCase();
    const sectionWrapper = `${type}Wrapper${this.state.layout.charAt(0).toUpperCase()}${this.state.layout.slice(1)}`;

    const bgImageActive =
      this.state.recStyles?.image &&
      (this.state.recStyles?.image.url || this.state.recStyles?.image.id) &&
      this.state.recStyles?.image.active &&
      !(this.state.section.type === 'INSTAGRAM' && this.state.section.styles.layout === 'design3');

    const story =
      (this.state.section.type === 'STORY' && this.state.layout !== 'fullLeft' && this.state.layout !== 'fullRight') ||
      this.state.section.type !== 'STORY';
    const datasheet =
      (this.state.section.type === 'DATASHEET' && this.state.layout === 'left') ||
      this.state.section.type !== 'DATASHEET';

    let paddingStyle = '';
    let style = {};

    if (this.state.recStyles?.paddingTop != null) {
      style.paddingTop = `${this.state.recStyles?.paddingTop}%`;
    }

    if (this.state.recStyles?.paddingBottom != null) {
      style.paddingBottom = `${this.state.recStyles?.paddingBottom}%`;
    }

    if (this.state.recStyles?.paddingLeft != null) {
      style.paddingLeft = `${this.state.recStyles?.paddingLeft}%`;
    }

    if (this.state.recStyles?.paddingRight != null) {
      style.paddingRight = `${this.state.recStyles?.paddingRight}%`;
    }

    if (
      this.state.section.type === 'ARTICLELOOP' &&
      this.state.section.styles.layout === 'hero' &&
      this.state.section.data[0].active === false &&
      this.state.section.data[1].active === false
    ) {
      style = { marginTop: '0' };
    } else if (
      story &&
      datasheet &&
      this.state.section.type !== 'CAROUSEL' &&
      this.state.section.type !== 'MAP' &&
      (this.state.recStyles?.styles.backgroundColor.active || bgImageActive) &&
      this.props.overlay !== true
    ) {
      paddingStyle = 'paddingTB120';
    } else if (
      story &&
      datasheet &&
      this.state.section.type !== 'CAROUSEL' &&
      this.state.section.type !== 'MAP' &&
      this.props.overlay !== true
    ) {
      paddingStyle = 'paddingTB60';
    } else if (
      story &&
      datasheet &&
      this.props.overlay === true &&
      this.state.section.type !== 'CAROUSEL' &&
      this.state.section.type !== 'MAP' &&
      (this.state.recStyles?.styles.backgroundColor.active || bgImageActive)
    ) {
      style.paddingTop = style.paddingTop ? `calc(${style.paddingTop} + ${navHeight}px)` : `${navHeight + 120}px`;
      paddingStyle = 'paddingB120';
    } else if (
      story &&
      this.props.overlay === true &&
      this.state.section.type !== 'CAROUSEL' &&
      this.state.section.type !== 'MAP' &&
      !this.props.pageContext.category
    ) {
      style.paddingTop = style.paddingTop ? `calc(${style.paddingTop} + ${navHeight}px)` : `${navHeight + 60}px`;
      paddingStyle = 'paddingB60';
    } else if (
      this.props.overlay === true &&
      ((this.state.section.type === 'STORY' &&
        (this.state.layout === 'fullLeft' || this.state.layout === 'fullRight')) ||
        this.state.section.type === 'CAROUSEL')
    ) {
      style.paddingTop = style.paddingTop ? `calc(${style.paddingTop} + ${navHeight}px)` : `${navHeight}px`;
    } else if (this.props.overlay === true && this.state.section.type === 'MAP') {
      style = { marginTop: `${navHeight}px` };
    } else if (this.props.pageContext.category && this.props.overlay) {
      style.paddingTop = `${navHeight + 90}px`;
    }

    if (this.state.section.type === 'HEROSLIDER') {
      const i = this.state.section.data.findIndex((e) => e.type === 'DATA');
      const height = i > -1 ? this.state.section.data[i].height : 70;
      style['min-height'] = `${height}vh`;
    }

    let paddingTop;
    if (this.props.section.type === 'ARTICLEMOTHER') {
      paddingTop = { paddingTop: style.paddingTop };
      style.paddingTop = undefined;
    }

    let parallax =
      (this.state.section.type === 'HERO' && this.state.section.styles.image.parallax && !this.props.mobile) || false;
    let parallaxCSS = {};

    if (parallax) {
      const imageSet = imgHelper.constructParallaxImage(
        this.state.recStyles?.image.id,
        this.state.recStyles?.image.url || this.state.recStyles?.image.src,
        this.state.recStyles?.image.CDNLink,
        this.state.recStyles?.image.alt,
        this.props.pageContext.images,
        this.props.pageContext.stockImages,
      );

      if (imageSet) {
        const bestQ = imgHelper.getBestQuality(
          imageSet._id,
          this.props.pageContext.images,
          this.props.pageContext.stockImages,
        );

        parallaxCSS = {
          '--primary': `url("${bestQ.primary}")`,
          '--fallback': `url("${bestQ.fallback}")`,
          backgroundPosition: `${this.state.recStyles?.image.position}`,
          backgroundSize: `${this.state.recStyles?.image.size}`,
          opacity: `${this.state.recStyles?.image.opacity}`,
        };
      } else {
        parallax = false;
      }
    }

    return (
      <div id={`${this.state.section._id}_backgroundSection`} className={styles.sectionWrapper} style={bg}>
        <div className={styles.imgOverlayWrapper}>
          {bgImageActive && (
            <div
              style={parallax ? parallaxCSS : null}
              className={`${
                parallax ? styles.sectionBackgroundWrapperParallax : styles.sectionBackgroundWrapper
              } has-fallback`}
            >
              {!parallax && (
                <Image
                  id={this.state.recStyles?.image.id}
                  url={this.state.recStyles?.image.url || this.state.recStyles?.image.src}
                  alt={this.state.recStyles?.image.alt}
                  position={this.state.recStyles?.image.position}
                  opacity={this.state.recStyles?.image.opacity}
                  sizes="100vw"
                  size={size}
                  images={this.props.pageContext.images}
                  imageStyleName={`sectionBackgroundImage${
                    (this.state.recStyles?.fullBg ?? this.state.recStyles?.full) === true ? 'Full' : ''
                  }`}
                  loadingStrategy={this.props.isFirst ? 'eager' : undefined}
                />
              )}
            </div>
          )}
          {overlay && (
            <div
              style={overlay}
              className={styles[`sectionOverlay${this.state.recStyles?.full === true ? 'Full' : ''}`]}
            />
          )}
          {(this.state.section.type === 'HERO' ||
            this.state.section.type === 'TEAM' ||
            (this.state.section.type === 'QUOTE' && this.state.layout === 'left')) &&
            this.state.recStyles?.box !== undefined &&
            this.state.recStyles?.box.styles.backgroundColor.active && (
              <div className={styles.sectionBoxWrapper}>
                <div className={styles[`sectionBoxPositioner${this.state.recStyles?.full === true ? 'Full' : ''}`]}>
                  <div
                    style={boxStyle}
                    className={[styles[`${type}ColorBox`], this.state.recStyles?.box.align || ''].join(' ')}
                  />
                </div>
              </div>
            )}
        </div>
        <Link
          name={this.props.pageContext.sectionSlugs[this.state.section._id]}
          id={`#${this.props.pageContext.sectionSlugs[this.state.section._id]}`}
          to={`/#${this.props.pageContext.sectionSlugs[this.state.section._id]}`}
          draggable="false"
        />
        <div className={`${styles[sectionWrapper]} ${styles[paddingStyle]}`} style={style}>
          {this.state.SpecificLayout ? (
            <this.state.SpecificLayout
              section={this.state.section}
              layout={this.state.layout}
              template={template}
              themeData={this.props.pageContext.themeData}
              full={full}
              articleMother={articleMother}
              siteLanguage={this.props.pageContext.language}
              metaLanguage={this.props.pageContext.metaLanguage}
              images={this.props.pageContext.images}
              badge={this.props.pageContext.badge}
              pagePathList={this.props.pageContext.pagePathList}
              articlePathList={this.props.pageContext.articlePathList}
              filePathList={this.props.pageContext.filePathList}
              categoryPathList={this.props.pageContext.categoryPathList}
              buttons={this.props.pageContext.buttons}
              boxStyle={boxStyle}
              matches={this.props.matches}
              footerLinks={this.props.pageContext.footerLinks}
              category={this.props.pageContext.category}
              backgroundColor={backgroundColor}
              paddingTop={paddingTop}
              cookie={this.props.pageContext.cookie}
              onCookieClick={this.props.onCookieClick}
              sectionSlugs={this.props.pageContext.sectionSlugs}
              isFirst={this.props.isFirst}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

LayoutContainer.contextType = InfoRowHeightContext;

export default LayoutContainer;
